<template>
    <div>
        <b-row class="content-header">
            <!-- Content Left -->
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="float-left mb-0">Voucher</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-card title="Redeem A Voucher">
            <!-- alert -->
            <b-alert :variant="variant" show v-if="status">
                <div class="alert-body">{{ status }}</div>
            </b-alert>
            <p class="mb-75">
                If you have a voucher, simply enter the code here to upgrade
                your account.
            </p>
            <b-overlay :show="overlay" rounded="sm" variant="transparent">
                <validation-observer ref="voucherForm" #default="{ invalid }">
                    <!-- form -->
                    <b-form @submit.prevent="onSubmit" v-if="show">
                        <b-row>
                            <b-col md="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Voucher"
                                    rules="required"
                                >
                                    <b-form-input
                                        placeholder="Voucher"
                                        name="voucher"
                                        v-model="voucher"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    /><small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider></b-col
                            ><b-col md="12"
                                ><b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    :disabled="invalid"
                                    type="submit"
                                    class="mt-1"
                                >
                                    Use voucher
                                </b-button></b-col
                            ></b-row
                        >
                    </b-form>
                </validation-observer>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BForm,
    BOverlay,
    BAlert,
    BCard,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BOverlay,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BButton,
        BForm,
        BAlert,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            variant: "",
            status: "",
            overlay: false,
            show: true,
            voucher: "",
        };
    },
    methods: {
        resetForm() {
            this.voucher = "";
            this.status = "";
            this.variant = "";
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        onSubmit() {
            this.status = "";
            this.overlay = true;
            this.$store
                .dispatch("redeemVoucher", this.voucher.toUpperCase())
                .then((res) => {
                    if (res.data.success) {
                        this.$store.dispatch("fetchUser");
                        this.resetForm();
                        this.variant = "success";
                    } else {
                        this.variant = "danger";
                    }
                    this.status = res.data.message;
                    this.overlay = false;
                })
                .catch(() => {
                    this.overlay = false;
                });
        },
    },
};
</script>

<style></style>
